<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">chercher</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="chercher"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'create-estimate' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter Devis</span>
        </b-button>
      </b-form-group>

    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      style-class="vgt-table striped bordered condensed"
      :columns="columns"
      :rows="estimates"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>

            <router-link
              v-if="props.row.type === 'gsr'"
              class="text-success"
              :to="{ name: 'show-estimate',
                     params: { id: props.row.id} }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>
            <router-link
              v-if="props.row.type === 'Kacem Mohamed'"
              class="text-success"
              :to="{ name: 'show-estimate-kacem',
                     params: { id: props.row.id} }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>
            <router-link
              v-if="props.row.type === 'Radhia Khalfallah'"
              class="text-success"
              :to="{ name: 'show-estimate-radhia',
                     params: { id: props.row.id} }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>
            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
            <span>
              <router-link
                class="text-warning"
                :to="{ name: 'edit-estimate',
                       params: { id: props.row.id} }"
              > <feather-icon
                :id="`invoice-row-${props.row.id}-update-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
              /></router-link>
              <b-tooltip
                title="Modifier"
                class="cursor-pointer"
                :target="`invoice-row-${props.row.id}-update-icon`"
              />
            </span>
            <span>
              <feather-icon
                :id="`invoice-row-${props.row.id}-trash-icon`"
                icon="TrashIcon"
                color="red"
                class="cursor-pointer"
                size="16"
                @click="deleteEstimate(props.row.id)"
              />
              <b-tooltip
                title="Supprimer"
                class="cursor-pointer"
                :target="`invoice-row-${props.row.id}-trash-icon`"
              /></span>

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{ props.total }} résultats retrouvés</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Reference Devis',
          field: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Reference',
          },
        },
        {
          label: 'Client',
          field: 'customer',
          filterOptions: {
            enabled: true,
            placeholder: 'Fournisseur',
          },
        },
        {
          label: 'Date',
          field: 'date',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche date',
          },
        },
        {
          label: 'Total ttc',
          field: 'price_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche total ttc',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      estimates: [],
    }
  },

  created() {
    this.getEstimates()
  },
  methods: {
    deleteEstimate(invoice) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axiosIns
            .put(`/estimates/delete/${invoice}/`)
            .then(() => {
              this.load = 'false'
              this.getEstimates()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Devis supprimé avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Devis Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      return `${day1.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    },

    async getEstimates() {
      const { data } = await axiosIns.get('/estimates/')
      this.estimates = data
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
